<template>
  <v-row
    :style="{ 'flex-direction': direction }"
    tag="ul"
    role="menubar"
    class="NavLinks"
  >
    <v-col v-for="(item, i) in items" :key="i" cols="auto" tag="li" role="none">
      <NuxtLink
        :to="item.to"
        class="nav-item"
        role="menuitem"
        :aria-label="item.title"
        :style="{ color }"
      >
        <v-icon
          v-if="showIcons"
          color="rgb(165 132 238 / 58%)"
          aria-hidden="true"
          :icon="item.icon"
        />
        {{ item.title }}
      </NuxtLink>
    </v-col>
  </v-row>
</template>

<script setup>
import {
  mdiCommentAccount,
  mdiHelp,
  mdiEmail,
  mdiNewspaperVariantOutline,
  mdiBagChecked,
} from '@mdi/js';
defineOptions({
  name: `NavLinks`,
});

const items = [
  {
    icon: mdiBagChecked,
    title: `Companies`,
    to: `/companies`,
  },
  {
    icon: mdiCommentAccount,
    title: `Give feedback`,
    to: `/give-feedback`,
  },
  {
    icon: mdiHelp,
    title: `How It works`,
    to: `/#how-it-works`,
  },
  {
    icon: mdiEmail,
    title: `Contact`,
    to: `/contact`,
  },
  {
    icon: mdiNewspaperVariantOutline,
    title: `Blog`,
    to: `/blog`,
  },
];

defineProps({
  direction: {
    type: String,
    required: true,
  },
  showIcons: {
    type: Boolean,
    require: true,
  },
  color: {
    type: String,
    required: false,
    default: 'black',
  },
});
</script>

<style scoped>
.nav-item {
  text-decoration: none;
  text-transform: capitalize;
}

.NavLinks {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
